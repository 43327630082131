@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");

/* font-family: "Nunito", sans-serif; */

@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy-Regular.woff2") format("woff2"),
    url("../fonts/Gilroy-Regular.woff") format("woff"),
    url("../fonts/Gilroy-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy-Medium.woff2") format("woff2"),
    url("../fonts/Gilroy-Medium.woff") format("woff"),
    url("../fonts/Gilroy-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy-Light.woff2") format("woff2"),
    url("../fonts/Gilroy-Light.woff") format("woff"),
    url("../fonts/Gilroy-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy-SemiBold.woff2") format("woff2"),
    url("../fonts/Gilroy-SemiBold.woff") format("woff"),
    url("../fonts/Gilroy-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-ExtraBold.woff2") format("woff2"),
    url("../fonts/Poppins-ExtraBold.woff") format("woff"),
    url("../fonts/Poppins-ExtraBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Black.woff2") format("woff2"),
    url("../fonts/Poppins-Black.woff") format("woff"),
    url("../fonts/Poppins-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-BlackItalic.woff2") format("woff2"),
    url("../fonts/Poppins-BlackItalic.woff") format("woff"),
    url("../fonts/Poppins-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-BoldItalic.woff2") format("woff2"),
    url("../fonts/Poppins-BoldItalic.woff") format("woff"),
    url("../fonts/Poppins-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Bold.woff2") format("woff2"),
    url("../fonts/Poppins-Bold.woff") format("woff"),
    url("../fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Light.woff2") format("woff2"),
    url("../fonts/Poppins-Light.woff") format("woff"),
    url("../fonts/Poppins-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Medium.woff2") format("woff2"),
    url("../fonts/Poppins-Medium.woff") format("woff"),
    url("../fonts/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-ExtraLight.woff2") format("woff2"),
    url("../fonts/Poppins-ExtraLight.woff") format("woff"),
    url("../fonts/Poppins-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-MediumItalic.woff2") format("woff2"),
    url("../fonts/Poppins-MediumItalic.woff") format("woff"),
    url("../fonts/Poppins-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-ExtraBoldItalic.woff2") format("woff2"),
    url("../fonts/Poppins-ExtraBoldItalic.woff") format("woff"),
    url("../fonts/Poppins-ExtraBoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Italic.woff2") format("woff2"),
    url("../fonts/Poppins-Italic.woff") format("woff"),
    url("../fonts/Poppins-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-LightItalic.woff2") format("woff2"),
    url("../fonts/Poppins-LightItalic.woff") format("woff"),
    url("../fonts/Poppins-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-ExtraLightItalic.woff2") format("woff2"),
    url("../fonts/Poppins-ExtraLightItalic.woff") format("woff"),
    url("../fonts/Poppins-ExtraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Regular.woff2") format("woff2"),
    url("../fonts/Poppins-Regular.woff") format("woff"),
    url("../fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-SemiBold.woff2") format("woff2"),
    url("../fonts/Poppins-SemiBold.woff") format("woff"),
    url("../fonts/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Thin.woff2") format("woff2"),
    url("../fonts/Poppins-Thin.woff") format("woff"),
    url("../fonts/Poppins-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-SemiBoldItalic.woff2") format("woff2"),
    url("../fonts/Poppins-SemiBoldItalic.woff") format("woff"),
    url("../fonts/Poppins-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-ThinItalic.woff2") format("woff2"),
    url("../fonts/Poppins-ThinItalic.woff") format("woff"),
    url("../fonts/Poppins-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

body,
html {
  font-family: "Poppins", sans-serif !important;
  overflow-x: hidden;
  height: 100%;
}

body {
  background: #040119 !important;
}

:root {
  --btn-theme: linear-gradient(97deg, #6a2b91 0%, #dc058d 100%);
  --theme-blue: #01336c;
  --theme-pink: #b0148e;
  --theme-purple: #6a2b91;
  --theme-body: #040119;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Gilroy" !important;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
a {
  cursor: poPoppins !important;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
p {
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #000000;
}

section.header-main {
  /* padding: 42px 30px 6px 40px;
  width: 100%; */
  background-color: var(--btn-theme);
  /* position: fixed;
  top: 0;
  z-index: 3; */
  transition: 0.3s ease-in-out;
  border-bottom: 2px solid #000;
  position: sticky;
  top: 0;
  background: #040119;
  z-index: 9;
}

section.header-main.fixed-header .container {
  border: none;
  padding: 0;
}
section.header-main.fixed-header {
  position: fixed;
  z-index: 3;
  transition: 0.3s ease-in-out;
  /* background: rgba(255, 255, 255, 0.15); */
  background: #000;
  backdrop-filter: blur(2px);
}

section.header-main.fixed-header a.navbar-brand {
  width: 210px;
}
section.header-main.fixed-header ul.navbar-nav li a {
  color: #fff !important;
}
section.header-main.fixed-header nav.navbar.navbar-expand-lg {
  padding: 0;
}

ul.social-list li {
  list-style-type: none;
}
ul.social-list {
  margin: 0;
  padding: 0 20px;
  display: flex;
  gap: 15px;
}
ul.social-list li a {
  padding: 0;
}
ul.navbar-nav li a {
  color: #000 !important;
  font-family: "Nunito";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

ul.navbar-nav li a.active {
  font-weight: 900;
}

ul.navbar-nav li a:hover {
  color: var(--btn-theme);
  /* background: var(--btn-theme); */
  /* -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
}
ul.navbar-nav li a::before {
  content: "";
  height: 2px;
  width: 100%;
  border-radius: 20px;
  background: #000;
  transform: scaleX(0);
  position: absolute;
  bottom: -5px;
  left: 0;
  transition: 0.3s ease-in-out 0s;
}
ul.navbar-nav li a:hover::before {
  transform: scaleX(1);
  transition: 0.3s ease-in-out 0s;
}
section.header-main.fixed-header ul.navbar-nav li a:hover::before {
  background: var(--btn-theme);
}
ul.navbar-nav li a:hover,
ul.navbar-nav li a:focus {
  color: var(--btn-theme);
}

button.close-menu {
  display: none;
}
nav.navbar.navbar-expand-lg {
  width: 100%;
}
.dropdown-content ul {
  padding: 0;
  margin: 0;
}
.dropdown-content ul li {
  list-style-type: none;
  padding: 0 !important;
}
.dropdown-content ul li a {
  color: #2c2c2c !important;
  padding: 10px;
  text-align: left;
}

.dropdown-content ul li a:hover {
  background: var(--btn-theme);
  color: #2c2c2c !important;
}
.dropdown-content ul li a::before {
  content: unset !important;
}
a.sigb-up-link {
  width: 116px;
  height: 39px;
  background: var(--btn-theme);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  line-height: normal;
  text-transform: capitalize;
  transition: 0.1s ease-in-out 0s;
}
a.nav-link.sigb-up-link:hover {
  background: linear-gradient(30deg, #cf7bf4 0%, #2745ea 100%);
  transition: 0.1s ease-in-out 0s;
  color: #fff;
}

div.tab-frame input {
  display: none;
}

div.tab-frame label {
  display: block;
  float: left;
  padding: 12px 30px;
  margin-right: 10px;
  cursor: pointer;
  color: #fff;
  text-align: center;
  font-family: "Gilroy";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

div.tab-frame label::before {
  content: "";
  height: 2px;
  width: 100%;
  background-color: #b0148e;
  position: absolute;
  left: 0;
  bottom: -1px;
  border-radius: 15px;
  display: none;
}
div.tab-frame input:checked + label::before {
  display: block;
}
div.tab-frame input:checked + label {
  cursor: pointer;
  position: relative;
  color: #fff;
}

div.tab-frame div.tab {
  display: none;
  padding: 5px 10px;
  clear: left;
}

div.tab-frame input:nth-of-type(1):checked ~ .tab:nth-of-type(1),
div.tab-frame input:nth-of-type(2):checked ~ .tab:nth-of-type(2),
div.tab-frame input:nth-of-type(3):checked ~ .tab:nth-of-type(3),
div.tab-frame input:nth-of-type(4):checked ~ .tab:nth-of-type(4),
div.tab-frame input:nth-of-type(5):checked ~ .tab:nth-of-type(5) {
  display: block;
}
form.get-in-form input {
  display: block;
  border-radius: 12px;
}
ul.navbar-nav li {
  padding: 0 10px;
}

.wallet-section .dropdown-menu.show {
  display: block;
  background: #e3e2df;
  border-radius: 0px 0px 10px 10px;
  min-width: 148px;
  border: 1px solid #000;
  transform: translate(0px, 50px) !important;
  left: -120px !important;
}
.wallet-section .dropdown-menu.show::before {
  content: "";
  position: absolute;
  top: -9px;
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 9px solid #e3e2df;
  right: 9px;
  filter: drop-shadow(0px -1px #000);
}

.wallet-section .dropdown-menu.show button a {
  color: #000;
  text-align: center;
  font-family: Nunito !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  text-decoration: none;
  display: block;
}
.wallet-section button.dropdown-item {
  text-align: center;
  padding: 0;
}

.wallet-section .dropdown-menu.show .dropdown-divider {
  border-top: 1px solid #000;
  margin: 0px 0;
}
.wallet-section button.dropdown-item:hover {
  background: var(--btn-theme);
  color: #fff;
}
.footerlogo_here {
  text-align: left;
}
.footerlogo_here img {
  width: 80px;
  height: 80px;
}
ul.footer-links li {
  font-family: "Cowboy Cadaver";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  text-transform: uppercase;
  color: #fff;
  list-style-type: none;
}
ul.footer-links {
  display: flex;
  justify-content: center;
  gap: 50px;
  margin: 0;
  padding: 0;
  margin-top: 33px !important;
}
.form-group {
  display: flex;
  align-items: center;
}
.footerform {
  text-align: left;
  width: 70%;
  margin: 0px auto;
}
.footerform form label {
  font-family: "Recoleta Alt" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #e29831;
}
.footerform form input {
  background: #ffffff;
  border: 2px solid #f1f1f1;
  border-radius: 45.595px;
  padding: 15px 18px;
  height: 60px;
}
.footerform form input::placeholder {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  color: #959595;
}
.footerform form input:focus {
  box-shadow: unset !important;
}
button.Signup_Now {
  position: absolute;
  right: 3px;
  border-radius: 45.595px;
  background-color: var(--btn-theme);
  border: none;
  height: 54px;
  width: 128px;
}
.footer-copyright p {
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}
.footer-copyright p span {
  color: var(--text-color);
}
section.footer-main {
  padding: 80px 0;
  background: #241f20;
  overflow: hidden;
}

.footer-copyright {
  background: #202020;
  padding: 22px 0;
  margin-top: 30px;
}

section.signup_main {
  height: 100%;
  min-height: 100vh;
  background-color: var(--theme-bg);
}

/* sign up pages */

/* sign up pages */

/* FAQ */

/* sign up css */

.sign_up_inner {
  width: 100%;
  padding: 40px 70px 40px;
  text-align: left;
}
h3.Welcome {
  color: #241f20;
  font-family: Snug Low;
  font-size: 3.5vw;
  font-style: normal;
  font-weight: 900;
  line-height: 70px;
  margin: 0;
}
h2.Signup_head {
  color: var(--theme-text);
  font-family: Nunito !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 150% */
  margin-bottom: 0px;
}

button.eye_btn {
  background: transparent;
  border: none;
  padding: 0;
  position: absolute;
  right: 15px;
  bottom: 15px;
}
button.eye_btn:hover,
button.eye_btn.active {
  background: transparent !important;
}
.check_box {
  text-align: left;
}
.check_box input {
  padding: 0px !important;
  height: 20px;
  width: 20px;
}
.check_box label {
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
a.forgot_password {
  color: #241f20 !important;
  font-family: Nunito !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px;
}
.left_sign_img img {
  width: 100%;
  min-height: 100vh;
  object-fit: cover;
}
.form_wraping {
  margin-top: 80px;
}
form.signup_form input::placeholder {
  color: #727272;
  font-family: Nunito !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px; /* 200% */
}
/* create nft css */

/* profile page */
.profile_banner {
  /* background-image: url(../../images/profilebanner.png); */
  background-size: cover;
  background-repeat: no-repeat;
  height: 200px;
}
.profile_bottom {
  padding: 0 27px;
}
.bio_profile {
  width: 206px;
  height: 203px;
  margin: 0px auto;
  border-radius: 20px;
}
.bio_profile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.market_bio_section {
  width: 50%;
  margin: -140px auto 0;
}
.bio_content h3 {
  font-family: "Cambria" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 24px;
  color: #000000;
}
.bio_content {
  margin-top: 30px;
}
.bio_content p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  margin: 18px 0 0;
}
.bio_content p span {
  color: #1888ef;
}
ul.bio_list {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-top: 30px;
}
ul.bio_list li {
  list-style-type: none;
  text-align: center;
}
ul.bio_list h4 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}
ul.bio_list p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #7e7c83;
  margin: 0;
}

.profile_side_inner {
  height: 100%;
  text-align: left;
}
section.profile_main {
  display: flex;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  position: fixed;
}

.profile_img_card {
  padding: 40px 14px 10px;
}
.imgeedit {
  width: 170px;
  height: 167px;
  margin: 0px auto;
  border-radius: 16px;
}
.imgeedit img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
a.edit_icon {
  position: absolute;
  z-index: 2;
  height: 25px;
  width: 25px;
  right: -3px;
  bottom: -4px;
}
.profile_Content h3 {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: #e29831;
}
.profile_Content p {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  margin: 0;
  opacity: 0.5;
}
ul.dash_nav {
  margin: 0;
  padding: 20px 10px;
  height: calc(100% - 10%);
  overflow-y: scroll;
  border-right: 1px dashed #fff;
}
ul.dash_nav::-webkit-scrollbar {
  width: 0px;
}
ul.dash_nav li {
  list-style-type: none;
  position: relative;
  margin: 8px 0;
}
/* ul.dash_nav li:not(:last-child) {
  margin-bottom: 6px;
} */
ul.dash_nav li a {
  display: flex;
  color: #fff;
  font-family: "Gilroy";
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-decoration: none;
  align-items: flex-start;
  text-align: start;
  padding: 12px 16px;
  position: relative;
  border: 2px solid transparent;
  background: transparent;
  border-radius: 10px;
}
ul.dash_nav li a:hover,
ul.dash_nav li a.active {
  box-shadow: 0px 3px 28px rgba(220, 5, 141, 0.2),
    inset 0px 3px 28px rgba(220, 5, 141, 0.1);
  background: transparent !important;
  border: 2px solid #b0148e !important;
}

ul.dash_nav li a.active span.icon-right img {
  filter: brightness(0) saturate(100%) invert(16%) sepia(88%) saturate(3201%)
    hue-rotate(298deg) brightness(91%) contrast(100%);
}
span.arrow-right {
  position: absolute;
  right: 30px;
}
ul.submenu li a,
ul.submenu li a:hover {
  background: transparent !important;
  box-shadow: unset !important;
}
.erro404page {
  width: 30%;
  text-align: center;
  margin: 0px auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.erro404page button.big-add-token {
  width: 100%;
  margin-left: 0 !important;
  border-radius: 10px;
  border: 2px solid #241f20;
  background: var(--btn-theme);
  box-shadow: 0px 2px 0px 0px #241f20;
  color: #fff;
  text-align: center;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  padding: 15px;
}
.erro404page button.big-add-token p {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}
section.errorpage {
  background-color: #000;
  height: 100vh;
  width: 100%;
}
.managetable_section table.table thead tr th:first-child {
  text-align: left;
}

.managetable_section table.table tbody tr td:nth-child(2) {
  text-align: left;
}

.pagination_list p {
  color: #aea7a7;
  font-family: "Gilroy" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}
ul.pagination {
  margin: 0;
  gap: 14px;
}
ul.pagination li a {
  border-radius: 6px;
  color: #505470;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
ul.pagination li a.active {
  background-color: var(--btn-theme);
  border-color: var(--btn-theme);
}
ul.pagination li a:hover {
  background-color: var(--btn-theme);
  color: #fff;
}

/* SUYM css */
a.navbar-brand {
  width: 26%;
}
.Explore_menu .dropdown button.btn.btn-secondary {
  padding: 0;
  background: transparent;
  border: none;
}
.user_wrap {
  height: 38px;
  width: 38px;
  background-color: #814705;
  border-radius: 100px;
}
.user_wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.banner_heading {
  background: var(--theme-bg);
  height: 100%;
}
.banner_img {
  background: var(--theme-orange);
  height: 100%;
  border: 2px solid #000;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 80px 0 0 110px;
}
.banner_heading {
  text-align: left;
}
.banner_heading h1 {
  color: #241f20;
  font-size: 8vw;
  font-style: normal;
  font-weight: 900;
  line-height: 114px;
}
.banner_heading p {
  color: #241f20;
  font-family: Nunito;
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: 40px; /* 114.286% */
}
.banner_content_div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
}
button.btn_common {
  width: 225px;
  height: 60px;
  border-radius: 10px;
  border: 2px solid #241f20;
  background: #ff9a00;
  box-shadow: 0px 2px 0px 0px #241f20;
  color: var(--theme-text);
  text-align: center;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

button.btn_common:hover {
  background: #ff4631;
  box-shadow: 0px 2px 0px 0px #241f20;
  border: 2px solid #241f20;
}

section.marquess_Lsit {
  background: #ff4631;
  padding: 10px 0;
}
span.pawicn {
  height: 40px;
  width: 40px;
}

span.pawicn img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.CardCstm h3 {
  color: #e4e0c3;
  font-family: Kalam !important;
  font-size: 3vw;
  font-style: normal;
  font-weight: 700;
  line-height: 66px;
  margin: 0;
  position: relative;
  top: 6px;
}

/* how it works section */
.com_pad {
  padding: 80px 0;
}
section.how_it_Works {
  background-color: var(--theme-bg);
}

.heading_main h3 {
  color: #241f20;
  text-align: center;
  font-size: 5vw;
  font-style: normal;
  font-weight: 900;
  line-height: 60px;
  margin-bottom: 30px;
}
.heading_main p {
  color: #241f20;
  text-align: center;
  font-family: Nunito !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  margin: 0;
  padding: 0 370px;
}
.howit_box {
  border-radius: 20px;
  border: 4px solid #241f20;
  background: #e3e2df;
  box-shadow: 0px 8px 0px 0px #241f20;
  padding: 50px 28px;
  height: 100%;
}
.howit_icon {
  height: 77px;
  width: 77px;
  margin: 0px auto 20px;
}
.howit_icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.howit_content h4 {
  color: var(--theme-text);
  text-align: center;
  font-family: Nunito !important;
  font-size: 22px;
  font-style: normal;
  font-weight: 900;
  line-height: 32px;
}
.howit_content p {
  color: var(--text-color);
  text-align: center;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 27px;
  margin: 0;
}
.learnmore_center button.btn_common {
  width: 33%;
}
/* how it works section */

/* foter menu */
.menu_common {
  width: 100%;
  position: relative;
}
.menu_common:not(:last-child):before {
  content: "";
  height: 90px;
  width: 2px;
  background-color: #ccc;
  position: absolute;
  right: 70px;
}
.foo_Logo {
  width: 26%;
  margin-bottom: 50px;
}
.menu_common ul {
  padding: 0;
  margin: 0;
}
.menu_common ul li {
  list-style-type: none;
}
.menu_common ul li:not(:last-child, ul.social_icon li) {
  margin-bottom: 10px;
}
.menu_common ul li a,
p.footer_subhead {
  color: #e3e2df;
  font-family: Nunito !important;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  text-decoration: none;
}
ul.social_icon {
  display: flex;
  gap: 10px;
  align-items: center;
}
ul.social_icon li {
  background: #ccc;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 100px;
}
ul.social_icon li a svg path {
  fill: #000;
}

.footer_form button.submit {
  width: auto;
  height: 49px;
  border-radius: 5px;
}
.footer_form .wrap_input input {
  height: 49px;
  border-radius: 5px;
}
/* foter menu */

.authbg {
  padding-top: 0;
  position: relative;
  height: 100%;
  min-height: 100vh;
  background-image: url(../images/loginimg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-size: 100%;
}
.form_Side {
  display: flex;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 526px;
  width: 100%;
  align-self: center;
  padding: 60px 48px;
  border-radius: 10px;
  background: #201d35;
  box-shadow: 0 3px 11px #00000029, inset 0 3px 11px #00000029;
  align-items: center;
  justify-content: center;
}
.pattern_circle {
  height: 60px;
  width: 60px;
  position: absolute;
  right: 36px;
  background: transparent;
  border-radius: 100px;
  top: 36px;
  border: 10px solid #aedfdb75;
}

.sign-in-top {
  padding-top: 36px;
  text-align: center;
  margin-bottom: 50px !important;
}

a.back_arrow {
  position: absolute;
  top: 30px;
  left: 40px;
  z-index: 2;
}

.input-group {
  padding: 11px 00px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  text-align: left;
}
.login_heading h3 {
  font-size: 24px;
  font-weight: 600;
}
.login_heading p {
  color: #9fa2b4;
  line-height: 26px;
  font-size: 14px;
}
.input-group label {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: left;
  color: #fff;
}
.input-group input {
  border-radius: 8px !important;
  border: 2px solid #979797;
  padding: 11px 16px;
  color: #fff;
  box-sizing: border-box;
  background: rgba(5, 3, 17, 0.4588235294);
  width: 100%;
  margin: 0;
  resize: none;
  font-size: 14px;
}
.input-group input:focus {
  outline: none;
}
.input-group input::placeholder {
  color: #9fa2b4;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.remember_me label.form-check-label {
  color: #fff;
  font-weight: 200;
  font-size: 14px;
}
.login_left_content {
  position: absolute;
  top: 120px;
  left: 90px;
  text-align: left;
  width: 220px;
}
.login_left_content h1 {
  color: #0d417a;
  font-weight: 700;
}
.login_left_content p {
  color: #041643;
  margin: 0;
  line-height: 26px;
}
button.themebtn {
  height: 46px;
  width: 100%;
  border: none;
  background: var(--btn-theme);
  border-radius: 8px;
  color: #fff;
  padding: 14px 30px;
  height: 50px;
  font-size: 16px;
  font-weight: 300;
  line-height: normal;
  transition: all 0.3s ease-in-out 0s;
}
button.themebtn:hover {
  transform: translateY(-2px);
}
button.themebtn:hover,
button.btn-save:hover,
button.btn-cancel:hover,
button.add_new_cust:hover,
button.import_btn:hover {
  background-color: #01336cc4;
}
a.colortextlink {
  color: #9fa2b4;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}
a.back_to_login {
  color: #b0148e !important;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  border-bottom: 2px solid #b0148e;
}
h6.menu_head {
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 20px;
  font-family: "Poppins", sans-serif;
}
.comon_dashpad {
  background: transparent;
  /* height: 100%; */
  min-height: 100vh;
  padding: 36px 35px;
}
.welcome_msg_dash h1 {
  color: #2e3a59;
  font-family: Poppins;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  margin: 0;
}
.welcome_msg_dash p {
  margin: 0;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.welcome_msg_dash p a {
  text-decoration: none;
  color: #36f;
}
.wave {
  animation-name: wave-animation;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
  display: inline-block;
  width: 20px;
  height: 20px;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  }
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.com_table table.table thead tr th {
  background: transparent;
  color: #fff;
  font-family: "Gilroy";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  vertical-align: middle;
  padding: 12px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3019607843);
}

.com_table table.table tbody tr td {
  color: #fff !important;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  vertical-align: middle;
  border: none;
  padding: 18px 13px;
  background: transparent;
}
.com_table table.table thead tr th {
  min-width: 130px;
}
.com_table table.table thead tr th:nth-child(1) {
  text-align: left;
  min-width: 70px;
}
.com_table table.table thead tr th .form-check {
  margin: 0;
}
.com_table table.table .form-check-input:checked {
  background-color: var(--btn-theme) !important;
  border-color: var(--btn-theme) !important;
}

.table_img {
  width: 52px;
  height: 52px;
  background: #3131316e;
  border-radius: 100px;
  overflow: hidden;
}
.table_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

section.com_table {
  background: #201d35;
  padding: 26px;
  border-radius: 10px;
}

.table_wrap {
  height: auto;
  max-height: 600px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.table_wrap::-webkit-scrollbar {
  width: 3px;
}

.table_wrap::-webkit-scrollbar-track {
  background: #fff;
}

.table_wrap::-webkit-scrollbar-thumb {
  background: var(--theme-pink);
}

.table_wrap::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.user_icon {
  width: 38px;
  height: 38px;
  border-radius: 100px;
  background-color: #eee;
}

.user_icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.table_user h5,
.table_user h5 a {
  color: #192a3e;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  text-decoration: none;
}

td.phone_number {
  color: #90a0b7 !important;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal;
  letter-spacing: 0.1px;
}
td.time_date p {
  margin: 0;
  color: var(--black, #192a3e);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: left;
  padding-left: 43px;
}
.table_scrool {
  height: 270px;
  overflow-y: scroll;
  padding-right: 20px;
}

.table_scrool::-webkit-scrollbar {
  width: 6px;
}
.table_scrool::-webkit-scrollbar-track {
  background: #ccc;
}
.table_scrool::-webkit-scrollbar-thumb {
  background: #000;
}
.table_scrool::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.status-active,
.status-inactive,
.status-open,
.status-close,
.status-expired,
.status-yellow {
  color: #16a62d;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 13px;
  background: rgba(36, 193, 143, 0.1);
  display: block;
  padding: 3px 0;
}

.status-expired {
  color: #ff1515;
  background: rgba(255, 0, 0, 0.1);
}

.status-open {
  color: #1565ff;
  background: rgba(21, 101, 255, 0.1);
}

.status-yellow {
  color: #d4af37;
  background: rgba(235, 255, 0, 0.1);
}

.status-inactive {
  color: #c12424;
  background: rgba(193, 36, 36, 0.1);
}
h5.filter_by {
  color: #2e3a59;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 28px;
  margin: 0;
}
.filter_head_button button {
  border-radius: 6px;
  background: #e4e4e4;
  border: none;
  height: 46px;
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  padding: 0 25px;
}
button.add_new_cust {
  background: var(--btn-theme);
  color: #fff;
}
.search_icon {
  position: absolute;
  left: 10px;
  bottom: 9px;
  height: 23px;
  width: 23px;
}

.search_icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  filter: brightness(0) saturate(100%) invert(94%) sepia(100%) saturate(2%)
    hue-rotate(212deg) brightness(107%) contrast(101%) !important;
  opacity: 0.4;
}

.filter_search div {
  margin: 0 !important;
}
.filter_search input {
  border-radius: 100px;
  height: 48px;
  border: 1px solid #eee;
  box-shadow: 0px 7px 9px #cecece40;
}
.filter_search input::placeholder {
  color: #9da3bb;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.filter_search input:focus {
  box-shadow: unset;
}
.search_icon svg {
  height: 1.2rem;
  width: 1.2rem;
  fill: #ccc;
}
.blockdrop_down .dropdown button.dropdown-toggle.btn.btn-secondary {
  background: #fff;
  height: 48px;
  border: 1px solid #eee;
  color: #9da3bb;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  box-shadow: 0px 7px 9px #cecece40;
}
.action_drop .dropdown button.btn.btn-secondary {
  background: transparent;
  border: none;
}
.action_drop .dropdown .dropdown-menu.show button {
  font-size: 13px;
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
}

.action_drop .dropdown .dropdown-menu.show {
  display: block;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  min-width: 107px;
}
section.manageprotable_section .time_date p {
  padding: 0;
}
td.location {
  color: #90a0b7 !important;
  font-family: Poppins;
  font-size: 11px !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.1px;
}

/* section.manageprotable_section table.table thead tr th:nth-child(3) {
 min-width: 150px;
} */
td.managepro_email {
  word-break: break-all;
  text-align: left;
}
/* section.manageprotable_section table.table thead tr th:nth-child(2) {
  min-width: 190px;
}
section.manageprotable_section table.table thead tr th:nth-child(6) {
  min-width: 190px;
} */
/* section.Managepropety .filter_head_button, section.Managebooking .filter_head_button{
  display: none !important;
} */
td.subscription_plan {
  color: #1565ff !important;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal;
}
.tableimg {
  height: 47px;
  width: 47px;
  border-radius: 5px;
  background-color: #eee;
  margin: 0px auto;
}
.tableimg img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.managefeedback_section table.table thead tr th:nth-child(6) {
  width: 300px;
}
section.manageissue_section td.managepro_email {
  text-align: center;
}

.user_icon_rect {
  width: 38px;
  height: 38px;
  border-radius: 5px;
  background-color: #eee;
}
.user_icon_rect img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}
.table_user_name h5 {
  color: #192a3e;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}
button.btn-delete {
  background: transparent !important;
  border: none;
}

.sub-menu ul {
  padding: 0 !important;
  margin: 6px 0;
}
section.reportsection .filter_search input {
  border-radius: 5px;
}
section.Bookingreportse_section {
  text-align: left;
}
.booking_content h5 {
  color: #2e3a59;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 28px;
}
.booking_content p {
  font-size: 15px;
  line-height: 21px;
  color: #2e3a59;
  font-family: Poppins;
}
/* .sub_menu ul li a {
  padding-left: 52px !important;
} */

.repost_graph_donut {
  height: 320px;
  width: 100%;
  background-color: #fff3f3;
}
.action_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.action_btn button {
  background-color: transparent !important;
  border: none;
  padding: 0;
  font-size: 20px;
  color: #000 !important;
}

section.roles_section table.table tbody tr td:nth-child(2) {
  text-align: left;
}
section.roles_section table.table tbody tr td {
  padding: 18px 12px;
}
.email_box_common {
  background: #f7f7f7;
  padding: 20px 20px 50px 20px;
}
.email_box_common ul {
  padding: 0;
  margin: 0;
}
.email_box_common ul li {
  list-style-type: none;
  display: flex;
}
.email_box_common ul li:not(:last-child) {
  margin-bottom: 15px;
}

h5.email_heading {
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.link_div,
.link_decription {
  width: 50%;
}

.link_div a,
.link_decription p {
  color: #1565ff;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
}
.link_decription p {
  color: #000;
  margin: 0;
}

section.emailbox_section .row {
  row-gap: 20px;
}
h5.Terminology_head {
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  opacity: 0.8;
  margin-bottom: 20px;
}
section.terminology_section {
  box-shadow: inset 5px 5px 22px #eee;
  padding: 24px 0;
  border-radius: 30px;
}
.card_common {
  padding: 20px;
  background: #fff;
  box-shadow: 0px 0px 50px #e2e2e2a8;
  border-radius: 10px;
}
.card_common label {
  font-size: 12px;
  font-family: Poppins;
  font-weight: 500;
}

.card_common select,
.card_common input {
  height: 42px;
  font-size: 14px;
}

.card_common select:focus,
.card_common input:focus {
  box-shadow: unset;
}

button.btn_submit {
  border-radius: 3px;
  font-size: 14px;
  padding: 6px 30px;
  background-color: var(--btn-theme);
  border-color: var(--btn-theme);
}
.img_gallry img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.img_gallry {
  width: 100%;
  height: 100%;
  background-color: #eee;
}
section.gallry_section .row {
  row-gap: 24px;
}
section.gallry_section {
  padding: 40px;
}
.chart_dropdown button.dropdown-toggle.btn.btn-secondary {
  border-radius: 10px;
  background: rgba(12, 5, 7, 0.05);
  border: none;
  color: #0c0507;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}
.income_analytics .sales_heading h5 {
  color: #fff;
}
.income_analytics .chart_dropdown button.dropdown-toggle.btn.btn-secondary {
  background: #fff;
  color: #0c0507;
}
.breadcrumb-item a,
.breadcrumb-item.active {
  font-family: "Gilroy";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  text-decoration: none;
  color: #fff;
}
.breadcrumb-item.active,
.breadcrumb-item + .breadcrumb-item::before,
.breadcrumb-item a:hover {
  color: var(--theme-pink) !important;
  text-decoration: none;
}
.breadcrumb-item a svg {
  font-size: 18px;
}
td.action_drop .dropdown-menu.show button a {
  color: #000;
  display: block;
  text-decoration: none;
}

.user_upload {
  height: 122px;
  width: 122px;
  background: #eee;
  margin: 0px auto;
  /* overflow: hidden; */
  border-radius: 100px;
  position: relative;
}

.user_upload img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100px;
  padding: 2px;
  position: absolute;
  left: 0;
  top: 0;
}
.user_upload input {
  display: none;
}
.user_upload label {
  height: 34px;
  width: 34px;
  border-radius: 100px !important;
  background: transparent !important;
  border: none !important;
  position: absolute;
  bottom: 10px;
  right: 4px;
  z-index: 3;
  cursor: poPoppins;
  margin: 0 !important;
}
.user_upload a {
  height: 43px;
  width: 44px;
  border-radius: 100px;
  position: absolute;
  bottom: 0px;
  right: -3px;
  z-index: 2;
}

.switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 14px;
  cursor: pointer;
  border-radius: 100px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: poPoppins;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: -1px;
  bottom: -4px;
  background-color: #ccc;
  transition: 0.4s;
}
input:checked + .slider {
  background-color: #e373be;
}

input:checked + .slider:before {
  background-color: var(--theme-pink);
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}
input:checked + .slider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
}
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}

button.delete_store {
  background: #ff7a7a;
  border: none;
}
.profile_sidebar {
  width: 290px;
  transition: transform 0.3s ease;
  transform: translateX(0px);
  overflow: hidden;
  transition: 0.3s ease-in-out;
}
section.sidebar-hidden .profile_sidebar {
  width: 80px;
  transition: 0.3s ease-in-out;
}
section.profile_side {
  width: 100%;
  height: 100%;
  /* border-right: 1px solid #000; */
  position: fixed;
}
.dashlogo {
  text-align: center;
  padding: 0;
  padding: 16px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
  background: transparent;
  z-index: 10;
  border-bottom-right-radius: 25px;
}
.profile_right_content {
  flex: 1;
  transition: margin-left 0.3s ease;
  width: 70%;
  padding: 0px 0px 40px 0px;
  overflow-y: scroll;
}
button.mobile_toggle_btn {
  background: transparent !important;
  padding: 0;
  border: none;
  /* display: none; */
  margin-bottom: 10px;
}

/* file upload */

/* jammena csss */
.toggle_heade_wrap {
  padding: 22px 30px 6px 40px;
  position: sticky;
  top: 0;
  background: #040119;
  z-index: 9;
}

.remember_me input {
  height: 18px;
  width: 18px;
  margin-right: 9px;
  background-color: transparent !important;
  border-radius: unset !important;
  border: 1px solid #fff;
}

.remember_me input:focus {
  box-shadow: unset !important;
}

.login_heading {
  width: 222px !important;
  margin: 0px auto 45px;
}
button.btn_eye {
  background: transparent !important;
  border: none;
  position: absolute !important;
  right: 11px;
  bottom: 22px;
  padding: 0;
}

.overlay_gradient {
  position: absolute;
  height: 240px;
  width: 350px;
  background: #b0148e;
  border-radius: 100%;
  opacity: 0.2;
  filter: blur(50px);
  top: 7px;
  left: -30px;
}

.icon-right {
  width: 32px;
  height: 32px;
  margin-right: 28px;
}
span.icon-right img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  filter: brightness(0) invert(1);
  min-width: 25px;
}

.togglebar span {
  background: #fff;
  height: 4px;
  display: block;
  margin: 5px 0;
  border-radius: 15px;
  transition: all 0.3s ease-in-out 0s;
  width: 30px;
}
.togglebar span:nth-child(2) {
  width: 35px;
  margin-left: auto;
}
.togglebar:hover span {
  width: 30px;
  transition: 0.3s all;
}
.user_login .dropdown button.btn.btn-secondary {
  display: flex;
  align-items: center;
  gap: 12px;
  background: transparent !important;
  border: none;
  padding: 0;
}

.walet_name_addrees h5 {
  margin: 0;
  font-size: 18px;
}
.walet_name_addrees p {
  margin: 0;
  color: #6f6f6f;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}
.add-wallet-wrap {
  gap: 32px;
  align-items: center;
}
.notification .dropdown button.btn.btn-secondary {
  background: transparent !important;
  padding: 0;
  border: none;
}
.notification .dropdown button.btn.btn-secondary img {
  filter: brightness(0) saturate(100%) invert(16%) sepia(88%) saturate(3201%)
    hue-rotate(298deg) brightness(91%) contrast(100%) !important;
}
.notification .dropdown-menu.show {
  min-width: 600px;
  left: -560px !important;
  padding: 8px;
  background: #040119;
}
h5.notify_head {
  color: #fff;
  font-size: 23px;
}

.search_form form div {
  margin: 0 !important;
}
.search_form form input,
.search_form input {
  margin: 0;
  background: #050311 !important;
  padding: 8px 8px 8px 46px;
  border-radius: 10px;
  color: #fff !important;
  border: 1px solid #979797;
  font-size: 14px;
}
.search_form form input::placeholder,
.search_form input::placeholder {
  color: #979797;
}
.search_form form input:focus,
.search_form input:focus {
  box-shadow: unset;
  border: 1px solid #979797;
}
.welcome_msg_dash h1 {
  font-size: 50px;
  color: #6f6f6f;
  font-weight: 200;
  margin-bottom: 12px;
}
.welcome_msg_dash h2 {
  font-size: 58px;
  color: #fff;
  font-weight: 500;
  margin: 0;
}

.common_box {
  background-color: #201d35;
  color: #fff;
}
.common_box h5 {
  display: flex;
  font-size: 14px;
  font-weight: 400;
  font-family: "Gilroy" !important;
}
.bottom_details p {
  margin: 0;
  color: #fff;
  font-weight: 200;
  font-size: 14px;
}
section.sidebar-hidden ul.submenu {
  padding: 0;
}
section.sidebar-hidden ul.submenu li a {
  border: none !important;
  background: transparent !important;
  box-shadow: unset !important;
  font-size: 12px;
  text-align: center;
  padding: 12px 0px;
  display: flex;
  justify-content: center;
}
section.sidebar-hidden ul.dash_nav li a {
  height: 59px;
  width: 59px;
}
.dashlogocollapse {
  height: 72px;
  width: 45px;
  margin: 0px auto;
}
.dashlogocollapse img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
h3.fillter_head {
  font-size: 27px;
  font-weight: 600;
  line-height: 1.25;
  color: #fff;
}
.fillter_by .search_form div {
  margin: 0 !important;
}
.fillter_by h5 {
  margin: 0;
  color: #fff;
  font-size: 18px;
}
.dropdown_col .dropdown button.dropdown-toggle.btn.btn-secondary {
  background: transparent !important;
  border: 0.5px solid #979797;
  padding: 12px 20px;
  color: #979797;
  font-size: 15px;
  width: 136px;
  text-align: left;
}
.dropdown_col .dropdown-toggle::after {
  position: absolute;
  right: 11px;
  top: 23px;
}
.dropdown_col .dropdown-menu.show button {
  font-size: 15px;
}
section.tabbing_common .clearfix {
  border-bottom: 1px solid;
}
.no_data p {
  margin: 0;
  color: #fff;
}
.single_notify_icon {
  background-color: #0b8351;
  height: 30px;
  width: 30px;
  border-radius: 100px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.single_notify_icon img {
  filter: invert(1);
}
.notify_single_line h5 {
  color: #fff;
  font-weight: 300;
  font-size: 18px;
  opacity: 0.5;
}
.notify_single_line p {
  margin: 0;
  color: #fff;
}
.notification .dropdown-menu.show .dropdown-item:hover {
  background: transparent !important;
}

.calender_set .rs-input-group.rs-input-group-inside {
  background-color: #050311 !important;
  border-radius: 10px;
  border: 1px solid #979797;
}
.export_btn {
  width: 264px;
  display: flex;
  justify-content: end;
  align-items: center;
}
button.export_csv,
button.resolve-chat {
  /* background: var(--btn-theme); */
  background: #b0148e;
  border: none;
  font-family: "Gilroy";
  font-size: 18px;
  padding: 12px 30px;
}

button.resolve-chat {
  padding: 5px 15px;
}
.fillter_by .search_form {
  width: 52%;
}
button.plussvg {
  height: 52px;
  width: 52px;
  padding: 0;
  border: 2px solid var(--theme-pink);
  background-color: var(--theme-body);
}
.categories-header h5 {
  font-size: 30px;
  color: #8d8d8d;
  font-weight: 600;
  margin: 0;
}
section.category_tab {
  background: #201d35;
  border-radius: 10px;
}
.tab_wrap {
  padding: 40px 134px;
}

div.category_frame label {
  font-size: 20px;
  border-bottom: 2px solid transparent;
  background: #46435b;
  min-width: 170px;
  height: 60px;
  padding: 10px 28px;
  color: #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Gilroy";
  font-weight: 300;
  margin-inline: 8px;
}

div.category_frame input:checked + label {
  background: #a7f9f6;
  color: #040119;
  border: none !important;
}
div.category_frame input:checked + label::before {
  display: none;
}
h5.heading-h5 {
  font-size: 20px;
  font-weight: 600;
  color: #6f6f6f;
  margin-bottom: 18px;
}
.Addacategory_mod .modal-content {
  background-color: #040119;
}
.addcategoriey h5 {
  color: #fff;
  font-size: 20px;
  font-family: "Gilroy";
  font-weight: 500;
}
.modal_input input {
  background: transparent;
  color: #fff;
  height: 48px;
}

.modal_input input::placeholder {
  font-size: 14px;
  color: #6f6f6f;
  display: block;
  margin-bottom: 10px;
}
.modal_input label {
  font-size: 16px;
  color: #6f6f6f;
  display: block;
  margin-bottom: 10px;
}

section.article_table {
  background: #46435a;
}
.skill_tab .tab_wrap {
  padding: 40px 40px 0;
}
.skilltab_frame .clearfix {
  border-bottom: 1px solid #4643596b;
}
.toggle_side h6 {
  color: #fff;
  margin: 0;
  font-size: 17px;
  font-weight: 500;
}

div.resources_frame label {
  background: #46435b;
  min-width: 170px;
  height: 60px;
  padding: 10px 28px;
  color: #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 22px;
}
div.resources_frame input:checked + label {
  cursor: pointer;
  position: relative;
  background: #a7f9f6;
  color: #040119;
  border: none !important;
}
div.resources_frame input:checked + label::before {
  content: unset;
}
div.resources_frame .clearfix {
  border: none;
}

h5.fillter_head {
  color: #fff;
  margin: 0;
}
.addresources_bottom_form {
  background: #201d35;
}
.images_wrap_show {
  height: 300px;
  border-radius: 7px;
  border: 2px solid #979797;
  background: rgba(5, 3, 17, 0.4588235294);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.upload_imge_vedio .image-item {
  width: 50%;
  height: 300px;
  padding: 10px;
}
.image-item img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

button.add_plusimg {
  width: 80px;
  height: 80px;
  border: 1px solid #fff;
  border-radius: 15px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent !important;
}
.image-item__btn-wrapper button {
  height: 30px;
  width: 30px;
  padding: 5px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: none;
}
.image-item__btn-wrapper {
  display: flex;
  gap: 10px;
  position: absolute;
  top: 10px;

  border-radius: 50%;
  right: 0;
}
p.upload_text {
  font-size: 14px;
  font-weight: 300;
  color: #626262;
  margin: 0;
  opacity: 0.5;
}

.pdf_upload {
  height: 300px;
  width: 300px;
  border-radius: 7px;
  border: 2px solid #979797;
  background: rgba(5, 3, 17, 0.4588235294);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.pdf_upload img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: unset;
  padding: 2px;
  position: absolute;
  left: 0;
  top: 0;
}
.pdf_upload input {
  display: none;
}
.pdf_upload label {
  height: 37px;
  width: 37px;
  border-radius: 100px !important;
  background: rgba(5, 3, 17, 1) !important;
  position: absolute;
  bottom: -20px;
  right: 4px;
  z-index: 3;
  cursor: pointer;
  margin: 0 auto !important;
  left: 0;
  border: 1px solid var(--theme-pink);
  background-image: url(../../assets/images/plusimg.png) !important;
  background-size: cover !important;
  background-position: center !important;
}
.pdf_upload a {
  height: 37px;
  width: 37px;
  border-radius: 100px;
  position: absolute;
  bottom: -1px;
  right: 0;
  z-index: 2;
  cursor: pointer;
  margin: 0 auto !important;
  left: -1px;
}
.file-preview p {
  color: #fff;
  margin: 0;
  font-weight: 200;
  font-family: "Gilroy";
}
.author_form label {
  color: #fff;
  opacity: 0.6;
  font-family: "Gilroy";
  font-weight: 300;
  font-size: 16px;
}
.author_form input,
.author_form select,
.author_form textarea {
  border-radius: 8px;
  border: 2px solid #979797;
  padding: 14px 16px;
  color: #fff;
  box-sizing: border-box;
  background: rgba(5, 3, 17, 0.4588235294);
  width: 100%;
  margin: 0;
  resize: none;
  font-family: "Gilroy";
  font-weight: 300;
}
.author_form input:focus,
.author_form select:focus,
.author_form textarea:focus {
  background: rgba(5, 3, 17, 0.4588235294);
  border-color: var(--theme-pink);
  box-shadow: unset;
}
.author_form input::placeholder,
.author_form select::placeholder,
.author_form textarea::placeholder {
  color: #fff;
  opacity: 0.6;
  font-family: "Gilroy";
  font-weight: 300;
}
.author_form textarea {
  height: 150px;
}
.downImg {
  position: absolute;
  right: 13px;
  bottom: 13px;
}
::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
.subadmin_first button.export_csv {
  padding: 12px 15px;
}
.phone_numberset input.PhoneInputInput {
  border: none;
  background: none;
  padding: 0;
}

.phone_numberset input.PhoneInputInput:focus {
  outline: none;
}
.PhoneInput {
  border-radius: 8px;
  border: 2px solid #979797;
  padding: 14px 16px;
  color: #fff;
  box-sizing: border-box;
  background: rgba(5, 3, 17, 0.4588235294);
  width: 100%;
  margin: 0;
  resize: none;
  font-family: "Gilroy";
  font-weight: 300;
}
.module_toggle th,
.module_toggle td {
  background: transparent !important;
  border: none;
  color: #fff !important;
  padding: 18px 0 !important;
}

.module_toggle th {
  font-weight: 300;
  font-weight: "Gilroy";
  opacity: 0.4;
  font-size: 16px;
}
.module_toggle td {
  font-weight: 300;
  font-size: 14px;
}

div.notificationtab_frame label {
  border: 2px solid #fff;
  border-radius: 10px;
  padding: 15px 25px;
  margin-right: 25px;
}

div.notificationtab_frame input:checked + label {
  cursor: pointer;
  position: relative;
  color: #fff;
  border: 2px solid var(--theme-pink);
}
div.notificationtab_frame input:checked + label::before {
  content: unset;
}

.slelct_all_check div {
  margin: 0px !important;
}
.slelct_all_check div label {
  margin: 0;
  color: #fff;
  font-weight: 300;
  font-size: 15px;
}

.reported_reason {
  background: #201d35;
  padding: 26px;
  border-radius: 10px;
}
button.add_reason {
  position: absolute;
  right: 3px;
  bottom: 3px;
  height: 50px;
  width: 49px;
  padding: 0;
  font-size: 30px;
  background-color: var(--theme-purple) !important;
  border: none;
}
.cmstabs_wrap {
  background: #201d35;
  padding: 26px;
  border-radius: 10px;
}

div.cms_frame label {
  font-size: 20px;
  border: 2px solid #fff;
  background: transparent;
  width: 94%;
  min-width: max-content;
  height: 52px;
  padding: 7px 8px;
  color: #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Gilroy";
  font-weight: 300;
  margin-inline: 8px;
}

div.cms_frame input:checked + label {
  background: transparent;
  color: #fff;
  border: 2px solid var(--theme-pink) !important;
}
div.cms_frame input:checked + label::before {
  content: unset !important;
}
.cmsslider {
  padding: 0 28px;
}

section.faqtabbing ul li button.nav-link {
  color: #fff;
  border-radius: unset;
  border: none;
  position: relative;
  padding: 14px 25px;
  font-size: 18px;
}

section.faqtabbing ul li button.nav-link::before {
  content: "";
  height: 3px;
  width: 100%;
  background-color: var(--theme-pink);
  position: absolute;
  left: 0;
  bottom: -1px;
  display: none;
}
section.faqtabbing ul li button.nav-link.active::before {
  display: block;
}
section.faqtabbing ul li button.nav-link.active {
  background-color: transparent;
  color: #fff;
}
.top_header h4 {
  color: #fff;
  margin: 0;
  font-size: 22px;
}
.line {
  width: 160px;
  height: 1px;
  background: #fff;
}
.innner_dark_card {
  background: #040119;
  min-height: 400px;
}
.top_header .dropdown button.dropdown-toggle.btn.btn-secondary {
  border: 1px solid #fff;
  padding: 10px 16px;
  background: #040119;
}

.gap-10 {
  gap: 10px;
}

/* .calendar_set {
  width: 300px;
} */

/* jammena csss */

.action_btns {
  width: 90px;
}
button.btn_action {
  background: transparent !important;
  padding: 0;
  border: unset !important;
}

.preivew_images_wrap {
  border-radius: 7px;
  border: 2px solid #979797;
  background: rgba(5, 3, 17, 0.4588235294);
  padding: 30px;
}
.preivew_images_wrap::-webkit-scrollbar {
  width: 0px;
}

.image_preview {
  width: 189px;
  height: 189px;
  border-radius: 5px;
  overflow: hidden;
  text-align: -webkit-center;
}
.image_preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.preivew_images_wrap .slick-slider {
  margin: 0 -8px;
}
.preivew_images_wrap .slick-slide {
  margin-right: 8px;
  margin-left: 8px;
}
.preivew_images_wrap .slick-list {
  height: 200px;
}
.preivew_images_wrap .slick-prev {
  left: -12px;
  z-index: 2;
}
.preivew_images_wrap .slick-next {
  right: -5px;
}
p.text_line {
  margin: 0;
  color: #fff;
  font-size: 14px;
  font-weight: 200;
}

.commonBtn {
  background: var(--btn-theme) !important;
  border: var(--btn-theme) !important;
}
.profile_set {
  position: unset !important;
  transform: unset !important;
  display: block;
  min-width: 100% !important;
  margin-top: 50px;
}
.profile-info-card h4 {
  color: #fff;
}

/* Flexbox to center the entire container */
.profile-container-wrapper {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100vh; /* Full viewport height */
}

.profile-picture-container {
  position: relative;

  text-align: center;
}

.profile-picture-wrapper {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%; /* Makes the image circular */
  overflow: hidden; /* Ensures the image fits within the circle */
}

.profile-picture-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user_login .dropdown-menu.show button.dropdown-item a {
  color: #000;
  text-decoration: none;
  font-size: 16px;
}

.profile-picture {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the entire circular area */
  border-radius: 50%; /* Keeps image in circle shape */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent dark overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%; /* Keeps overlay in circle shape */
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.camera-icon {
  color: white;
  font-size: 24px;
}

.profile-picture-wrapper:hover .overlay {
  opacity: 1; /* Shows overlay when hovered */
}

.author_form .input-group input {
  width: 100% !important;
}

.author_form .input-group button.btn_eye {
  position: absolute !important;
  bottom: 24px;
}
.profile_img {
  height: 110px;
  width: 110px;
  margin: 0px auto;
  border-radius: 100px !important;
  overflow: hidden;
}
.profile_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.flex_fields {
  display: flex;
  align-items: center;
  gap: 20px;
}
.flex_fields label.form-label {
  margin: 0 !important;
  font-weight: 600;
}
.dot {
  background: #b0148e;
  min-height: 20px;
  min-width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  padding: 3px;
  font-size: 11px;
  color: #fff;
  position: absolute;
  top: -10px;
  right: -10px;
}
.subadmin_pass button.btn_eye {
  bottom: 16px !important;
}

.notification-ui_dd-content {
  margin-bottom: 30px;
}

.notification-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 7px;
  background: #fff;
  -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.06);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.06);
}

.notification-list--unread {
  border-left: 2px solid #29b6f6;
}

.notification-list .notification-list_content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.notification-list .notification-list_content .notification-list_img img {
  height: 30px;
  width: 30px;
  border-radius: 50px;
  margin-right: 20px;
}

.notification-list .notification-list_content .notification-list_detail p {
  margin-bottom: 5px;
  line-height: 1.2;
}

.notification-list .notification-list_feature-img img {
  height: 48px;
  width: 48px;
  border-radius: 5px;
  margin-left: 20px;
}

.heading-line {
  position: relative;
  padding-bottom: 5px;
  color: white;
}

.heading-line:after {
  content: "";
  height: 4px;
  width: 75px;
  background-color: white;
  position: absolute;
  bottom: 0;
  left: 0;
}

.section-50 {
  padding: 10px;
}
.icon-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #007bff; /* Adjust icon color */
  font-size: 1.25rem; /* Icon size */
}

.icon-button:hover {
  color: #0056b3; /* Color on hover */
}

.gradient-custom {
  /* fallback for old browsers */
  background: #fccb90;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(
    to bottom right,
    rgba(252, 203, 144, 1),
    rgba(213, 126, 235, 1)
  );

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(
    to bottom right,
    rgba(252, 203, 144, 1),
    rgba(213, 126, 235, 1)
  );
}

.mask-custom {
  background: rgba(24, 24, 16, 0.2);
  border-radius: 2em;
  backdrop-filter: blur(15px);
  border: 2px solid rgba(255, 255, 255, 0.05);
  background-clip: padding-box;
  box-shadow: 10px 10px 10px rgba(46, 54, 68, 0.03);
}

p.custom_font {
  font-size: 12px;
  line-height: 20px;
}

.list_scroll {
  height: auto;
  max-height: 550px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.list_scroll::-webkit-scrollbar {
  width: 4px;
}

.list_scroll::-webkit-scrollbar-track {
  background: #eee;
}

.list_scroll::-webkit-scrollbar-thumb {
  background: #ccc;
}

/* responsive start */

@media only screen and (min-width: 1360px), (max-width: 1800px) {
}

@media only screen and (max-width: 1360px) {
}

@media only screen and (max-width: 1169px) {
  .container {
    max-width: 95% !important;
  }
  button.mobile_toggle_btn {
    display: block;
    position: absolute;
    z-index: 2;
    top: 45px;
    left: 30px;
  }

  .sidebar-hidden .profile_sidebar {
    transform: translateX(0px);
  }

  .profile_sidebar {
    width: auto;
    transform: translateX(-250px);
    padding: 0;
  }

  section.profile_side {
    padding: 18px 0px 0 15px;
  }
  .sidebar-hidden .profile_right_content {
    margin-left: 256px;
    padding: 20px 20px 100px 0px;
  }
  .profile_right_content {
    padding: 20px 20px 100px 20px;
  }
  .form_Side {
    width: 73%;
  }
  .sidebar-hidden .dash_home {
    width: 70%;
  }
  .sidebar-hidden .total_revnue_left {
    padding: 30px 160px 20px 20px;
  }
  .sidebar-hidden .filter_by {
    padding: 0 !important;
  }
  .sidebar-hidden .filter_head_button button {
    height: 42px;
    font-size: 13px;
    line-height: 18px;
    padding: 0 15px;
  }
  .sidebar-hidden h5.filter_by {
    font-size: 16px;
  }
  .dash_header {
    padding: 20px 20px 20px 60px;
  }
  .sidebar-hidden .welcome_msg_dash {
    flex-direction: column;
    gap: 2px !important;
  }
  .profile_sidebar {
    overflow: visible;
  }
}

@media only screen and (max-width: 1099px) {
}

@media only screen and (max-width: 991px) {
  ul.navbar-nav {
    position: absolute;
    top: 0;
    background: #000;
    left: -400px;
    width: 370px !important;
    z-index: 999;
    transition: 0.3s;
    height: 100vh;
    padding: 50px 0 0;
    box-shadow: 5px 5px 12px #11111157;
  }
  ul.navbar-nav li a {
    color: #fff !important;
  }
  .show ul.navbar-nav {
    left: 0;
    transition: 0.3s !important;
  }
  nav.navbar {
    width: 52%;
    position: inherit;
  }
  .navbar-collapse {
    transition: 3s;
  }
  button.close-menu {
    display: block;
    background: transparent;
    width: max-content;
    padding: 0;
    border: none;
    position: absolute;
    right: 10px;
    top: 10px;
  }
  button.navbar-toggler {
    border: none;
    height: 32px;
    width: 32px;
    padding: 0;
    display: grid;
    justify-content: center;
    align-items: center;
  }
  button.navbar-toggler:focus {
    box-shadow: unset;
  }

  button.navbar-toggler .bar {
    height: 2px;
    width: 32px;
    background: #fff;
    position: relative;
    transition: 0.2s ease-in-out;
  }
  .bar:nth-child(1) {
    transform: rotate(48deg) translate(12px, 5px);
  }
  .bar:nth-child(3) {
    transform: rotate(-48deg) translate(10px, -2px);
  }
  button.navbar-toggler .bar:nth-child(2) {
    opacity: 0;
  }
  button.navbar-toggler.menu_click .bar:nth-child(2) {
    opacity: 1;
  }
  button.navbar-toggler.menu_click .bar:nth-child(1) {
    transform: rotate(0deg) translate(0px, 0px);
  }
  button.navbar-toggler.menu_click .bar:nth-child(3) {
    transform: rotate(0deg) translate(0px, 0px);
  }
  ul.navbar-nav li a.active {
    color: var(--btn-theme) !important;
  }
}

@media only screen and (max-width: 1023px) {
}

@media only screen and (max-width: 979px) {
}

@media only screen and (max-width: 799px) {
  .form_Side {
    width: 90%;
  }
  .total_revnue_left {
    padding: 20px 70px 10px 20px;
  }
  .dash_home {
    position: absolute;
    right: -90px;
    bottom: 50px;
    width: 60%;
  }
  .filter_by {
    flex-direction: column;
    gap: 10px;
  }
  .filter_head_button button {
    height: 40px;
    padding: 0 20px;
  }
  .com_table table.table tbody tr td {
    min-width: 138px;
  }
  .details_wrap {
    padding: 0 0px 60px;
  }
  section.Statusbox ul li {
    flex: 1 0 0;
    width: 100%;
  }
  section.Statusbox ul {
    margin-top: 20px;
  }
  .addform_start {
    width: 100%;
  }
  .login_left_content {
    top: 110px;
    left: 40px;
    width: 200px;
  }
  .login_right_banner img {
    object-fit: inherit;
  }

  .forgor-password.mt-5 {
    margin-top: 20px !important;
  }
}

@media only screen and (max-width: 767px) {
  .dash_home {
    right: -120px;
    bottom: 0;
    width: 50%;
  }
  .total_revnue_left {
    height: auto;
  }
  .total_revnue_left {
    padding: 30px 140px 30px 20px;
  }
  .wraping_left,
  .Sales_analytics_left {
    margin-bottom: 23px;
  }
  section.Latestcustomers_table {
    padding: 30px 0;
  }
  .add_new_start {
    width: 100%;
  }
}

@media only screen and (max-width: 639px) {
  .form_Side {
    flex-direction: column;
    /* position: unset;
    transform: unset; */
    margin: 0;
    width: 90%;
  }
  form#loginform {
    width: 100%;
  }
  .authbg {
    position: relative;
    height: 100%;
    min-height: 100vh;
    padding: 60px 0;
  }
  .login_right_banner {
    width: 100%;
    margin: 0px auto;
  }
  .sign-in-top {
    padding-top: 0;
  }
  .erro404page {
    width: 80%;
  }
  .welcome_msg_dash {
    flex-direction: column;
    gap: 7px !important;
  }
  .total_revnue_left {
    padding: 30px 50px 30px 20px;
  }
  .dash_home {
    right: -90px;
  }
  .dash_header {
    padding: 20px 20px 20px 20px;
  }
  .welcome_msg_dash.gap-3 {
    gap: 7px !important;
  }
  section.total_revnue {
    padding: 10px 0 20px;
  }
  .filter_by.px-4 {
    padding: 0 !important;
  }
  .filter_head_button button {
    padding: 0 16px;
  }
  .filter_head_side {
    flex-wrap: wrap;
  }
  .filter_search {
    width: 100%;
  }
  .addform_common_inner {
    padding: 0px !important;
  }
  .form_input_add .row {
    row-gap: 0px;
  }
  .permission_toggle {
    width: 100%;
  }
  ol.breadcrumb {
    margin: 0;
  }
  .login_right_banner img {
    display: none;
  }
  .login_left_content {
    position: unset;
    padding: 20px;
    width: 100%;
    background: #f4f5f7;
  }
  .authbg {
    background-size: cover;
  }
  .header_userside.gap-4 {
    gap: 0 !important;
  }
  .user_content {
    display: none;
  }
  .dash_header {
    padding-left: 70px;
  }

  .com_table table.table tbody tr td {
    padding: 7px 13px;
    white-space: nowrap;
    min-width: unset;
  }
  .com_table table.table thead tr th {
    white-space: nowrap;
  }
  .bread_crumb,
  .procedurelistwrap_box {
    flex-direction: column;
  }
  .procedurelist_common,
  .procedurelist_common:nth-child(1) {
    width: 100%;
  }
  .addform_common_inner {
    overflow: hidden;
  }
  .sidebar-hidden .profile_right_content,
  .profile_right_content {
    padding: 0;
  }
  .comon_dashpad {
    border-radius: 0;
  }
  button.mobile_toggle_btn {
    top: 23px;
    left: 23px;
  }
  td.address_column {
    white-space: normal !important;
    min-width: 244px !important;
  }
  .bread_crumb {
    white-space: nowrap;
    flex-direction: row;
  }
}

@media only screen and (max-width: 479px) {
  .form_Side {
    padding: 30px 28px;
  }
  .login_heading {
    width: 182px !important;
    margin: 0px auto 15px;
  }
}

@media only screen and (max-width: 390px) {
  button.mobile_toggle_btn {
    left: 16px;
  }
}

@media only screen and (max-width: 386px) {
  .dash_header {
    padding-left: 40px;
    padding-right: 6px;
  }
  .user_img {
    width: 30px;
    height: 30px;
  }
  .userlogout svg {
    width: 28px;
    height: 28px;
  }
}

@media only screen and (max-width: 375px) {
  .dashprofile_actiontbn {
    flex-direction: column;
  }
}

.ql-editor p,
.ql-editor ol,
.ql-editor ul,
.ql-editor pre,
.ql-editor blockquote,
.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6 {
  color: white; /* Set default content color to white */
}
/* Set the toolbar background and text color to white */
.ql-toolbar {
  background-color: white; /* Set toolbar background color */
  color: black; /* Set toolbar icon and text color */
  border: none; /* Remove default border */
}

/* Set toolbar button hover and active states */
.ql-toolbar button {
  color: black; /* Set button icon color */
}

/* Change the hover color for toolbar buttons */
.ql-toolbar button:hover {
  color: #555; /* Darker color for hover */
}

/* Ensure dropdowns in the toolbar also have white background */
.ql-toolbar .ql-picker {
  color: black; /* Set dropdown text color */
  background-color: white; /* Set dropdown background color */
}

.ql-toolbar .ql-picker-options {
  background-color: white; /* Dropdown background */
  color: black; /* Dropdown text color */
}
.ql-container {
  height: 200px; /* Set a fixed height for the editor */
  overflow-y: auto; /* Enable vertical scrolling */
}

.ql-editor {
  height: auto; /* Allow content to expand within the defined height of the container */
  color: white; /* Keep text color white */
}

.image_preview {
  display: inline !important;
}

button.btn-close {
  --bs-btn-close-color: #fff; /* Set the cross button color to white */
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 1; /* Ensure the button is fully visible */
  --bs-btn-close-hover-opacity: 0.75; /* Adjust hover opacity */
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5); /* Set focus shadow */
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  background-color: #000; /* Set the button background color to black */
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  border: 0;
  border-radius: 0.375rem;
  opacity: var(--bs-btn-close-opacity);
}

.rs-input-group.rs-input-group-inside {
  /* background-color: unset !important; */
  height: 40px;
}

.rs-input-group-md > .rs-input {
  margin-top: 2px;
}
/* 
.media_preview{
  padding-top: 50px;
} */

.slick-track {
  display: flex !important;
  align-items: center !important;
}


.noUnderLine {
  
  text-decoration: none !important

}

.subadmin-table .action_btns.d-flex {
  display: block !IMPORTANT;
  width: auto;
}
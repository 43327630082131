.cstmPagination {
  p {
    font-size: 12px;
  }
  ul {
    margin: 0;
    gap: 5px;
    a,
    span {
      height: 26px;
      width: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #eff0f4;
      border-radius: 3px !important;
      font-size: 12px;
      color: #505470;
      span {
        border: 0;
      }
    }
  }
}
